export const environment = {
  production: false,
  serverPrefix: '/',
  backendURL: 'https://apidev.bindl.ru',
  backendBotURL: 'https://telegramdev.bindl.ru',
  backendAuthURL: 'https://authdev.bindl.ru',
  originUrl: 'https:/appdev.bindl.ru/',
  clientFormURL: 'https://clientdev.bindl.ru',
  testDesc: 'standart',
  linkMainPage: 'https://bindl.ru',
};
