import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {from, Observable, Subject} from 'rxjs';
import {debounceTime, pluck, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

import {warningStatuses} from '../helpers/constants';
import {PersonalDataEdit} from '../models/personal-data-edit.model';
import {DownloadService} from './download.service';
import {LoadingService, LoadingStates} from './loading.service';
import {TariffService} from './tariff.service';

@Injectable({
  providedIn: "root",
})
export class HTTPService {
  ServerURL = `${environment.backendURL}/v1`;
  ServerBotUrl = `${environment.backendBotURL}/v1`;
  ServerAuthURL = `${environment.backendAuthURL}/v1`;

  ScrollToTop$ = new Subject<boolean>();

  IsLoading$ = new Subject<boolean>();
  IsLoginedChange$ = new Subject<boolean>();
  IsLogined = false;

  IsLoadingChange$ = new Subject<boolean>();

  IsUserChange$ = new Subject<any>();
  User: any = {
    id: null,
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    avatar: "",
    company_name: "",
    has_establishment: false,
    partner_status: "",
    is_franchise: false,
    registered_at: "",
    utc_offset: 0
  };

  headers = new HttpHeaders({
    "Access-Control-Allow-Origin": "*",
  });

  // LoadingSubject: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    public loadingService: LoadingService,
    private tariffService: TariffService,
    private downloadService: DownloadService
  ) {
    this.IsLoginedChange$.next(false);
    //this.IsLoadingLogin$.next(false);
    this.IsLoginedChange$.subscribe((val) => {
      let prevIsLogined = this.IsLogined;
      this.IsLogined = val;
      //console.log("this.IsLoginedChange$.subscribe")
      if (this.IsLogined && !prevIsLogined) {
        this.router.navigate(["/", "base"]);
      } else if (
        this.IsLogined &&
        (this.isInsideAuthModule() || this.isInsideEntryModule())
      ) {
        this.router.navigate(["/", "base"]);
      }
      if (!this.IsLogined) {
        this.router.navigate(["auth", "login"]);
      }
    });
  }

  updateToken(newToken: string): void {
    localStorage.setItem('token', newToken);
    this.headers = this.headers.set('Authorization', `Bearer ${newToken}`);
  }

  CheckToken() {
    //console.log("CheckToken");
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      // this.isLoading = true;
      this.IsLoadingChange$.next(true);
      if (sessionStorage.getItem("token")) {
        this.BaseInitByToken(sessionStorage.getItem("token"));
        this.IsLogined = sessionStorage.getItem("token") ? true : false;
      } else if (localStorage.getItem("token")) {
        this.BaseInitByToken(localStorage.getItem("token"));
        this.IsLogined = localStorage.getItem("token") ? true : false;
      }

      this.GetMe(LoadingStates.MAIN).subscribe(
        (res) => {
          this.User = res;
          // this.isLoading = false;
          this.IsUserChange$.next(true);
          this.IsLoginedChange$.next(true);
          this.IsLoadingChange$.next(false);
        },
        (err) => {
          this.ClearSession();
        }
      );
    } else {
      // this.isLoading = false;
      this.ClearSession();
    }
  }

  BaseInitByToken(data) {
    if (data) {
      if (!this.headers.has("Authorization")) {
        this.headers = this.headers.append("Authorization", data);
      } else {
        this.headers = this.headers.set("Authorization", data);
      }
    }
  }

  private isInsideAuthModule() {
    return window.location.href.includes("auth") ? true : false;
  }

  private isInsideEntryModule() {
    return window.location.href.includes("entry") ? true : false;
  }

  Login(payload: { email: string; password: string }) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerAuthURL + "/partner/login", payload);
    }, LoadingStates.AUTH_LOGIN);
  }

  LoginAdmin(value: string) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerAuthURL + "/admin/partner/auth", {value});
    }, LoadingStates.AUTH_LOGIN);
  }

  ClearSession() {
    localStorage.setItem("token", "");
    localStorage.removeItem("token");
    sessionStorage.clear();
    // this.IsLoginedChange$.next(false);
    this.tariffService.clear();
    if (!this.isInsideAuthModule()) {
      this.router.navigate(["auth", "login"]);
    }
  }

  Logout() {
    return this.http
      .post(this.ServerAuthURL + "/logout", {}, {headers: this.headers})
      .subscribe((res) => {
        this.IsLoginedChange$.next(false);
        this.ClearSession();
      });
  }


  RegisterPartner(data: any) {
    return this.http.post(this.ServerAuthURL + '/partner/register', data)
    //return this.SendPost('/partner/register',data,this.loadingService.StatesNames.AUTH_REGISTER,this.ServerAuthURL)
  }

  CheckValidRegisterForm(data) {
    return this.RequestHandler(() => {
      return this.http.post(`${this.ServerAuthURL}/verify`, data);
    }, LoadingStates.AUTH_VALIDATE);
  }

  GetMe(State?) {
    return this.SendGet("/partner/me", State, this.ServerAuthURL).pipe(
      tap(({partner_status}) => {

        // TODO check on refactor pay system
        partner_status = 'paid' // REMOVE THIS
        // if (warningStatuses.includes(partner_status)) {
        this.tariffService.setStatus(partner_status);
        // }
      })
    );
  }

  ForgotPassword(data) {
    return this.SendPostAuth('/partner/reset-password', data, LoadingStates.FORGOT_PASSWORD);
  }

  editLoginPassword(data) {
    return this.http.post(
      this.ServerURL + "/user/edit",
      {
        phone: data.phone,
        password: data.password,
        old_password: data.old_password,
      },
      {headers: this.headers}
    );
  }

  editPersonalData(user: PersonalDataEdit) {
    //console.log("editPersonalData", user)
    return this.RequestHandler(() => {
      return this.http
        .put(this.ServerAuthURL + '/', user, {
          headers: this.headers,
        })
        .pipe(tap((user) => (this.User = Object.assign(this.User, user))));
    }, LoadingStates.SETTINGS);
  }


  editPersonal(user: any) {
    return this.SendPut('/partner', user, this.loadingService.StatesNames.SETTINGS, this.ServerAuthURL)
  }


  editPersonalUTC(utc_offset: number) {
    return this.RequestHandler(() => {
      return this.http
        .patch(this.ServerURL + "/user/edit/utc_offset", {utc_offset}, {
          headers: this.headers,
        })
      //.pipe(tap((user) => (this.User = Object.assign(this.User, user))));
    }, LoadingStates.SETTINGS);
  }


  fetchUserPurchaseHistory() {
    return this.SendGet(
      "/payments",
      LoadingStates.FETCH_USER_PURCHASE_HISTORY
    ).pipe(pluck("partner_payments"));
  }

  RequestHandler(
    runRequest: () => Observable<any>,
    RequestType = LoadingStates.UNKNOWN
  ): Observable<any> {
    let loadingId = this.loadingService.addLoading(RequestType);
    //console.log(`[Request handler] with ID = ${loadingId}`)
    //console.log(`[Request handler] with ID = ${RequestType}`)

    return runRequest().pipe(
      debounceTime(50),
      tap(
        (dataRes) => {
          //console.log(`${loadingId} : DataRes = `, dataRes)
          this.loadingService.removeLoading(loadingId);
        },
        (catchError) => {
          this.loadingService.removeLoading(loadingId);
        }
      )
    );
  }

  SendPost(method: string, data, type = LoadingStates.UNKNOWN, ServerURL = this.ServerURL) {
    return this.RequestHandler(() => {
      return this.http.post(ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendPostAuth(method: string, data, type = LoadingStates.UNKNOWN, ServerURL = this.ServerURL) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerAuthURL + method, data,
      );
    }, type);
  }

  SendPatch(method: string, data, type = LoadingStates.UNKNOWN, ServerURL = this.ServerURL) {
    return this.RequestHandler(() => {
      return this.http.patch(ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendDownloadPost(method: string, data, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + method, data, {
        headers: this.headers,
        responseType: "blob" as "json",
        observe: "response",
      });
    }, type);
  }

  SendDownloadGet(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURL + method, {
        headers: this.headers,
        responseType: "blob" as "json",
        observe: "response",
      });
    }, type);
  }

  SendPut(method: string, data, type = LoadingStates.UNKNOWN, ServerURL = this.ServerURL) {
    return this.RequestHandler(() => {
      return this.http.put(ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendGet(method: string, type = LoadingStates.UNKNOWN, ServerURL = this.ServerURL) {
    return this.RequestHandler(() => {
      return this.http.get(ServerURL + method, {headers: this.headers});
    }, type);
  }

  SendGetText(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http
        .get(this.ServerURL + method, {
          headers: this.headers,
          responseType: "text",
        })
        .pipe(
          tap((data) => {
            //console.log(data, typeof(data))
          })
        );
    }, type);
  }

  SendGetBot(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(
        this.ServerBotUrl + method,
        {headers: this.headers}
      )
    }, type);
  }


  SendDelete(method: string, data, type = LoadingStates.UNKNOWN, ServerURL = this.ServerURL) {
    return this.RequestHandler(() => {
      return this.http.delete(ServerURL + method, {
        headers: this.headers,
      });
    }, type);
  }

  GetInfoCard(card_id) {
    return this.SendGet(`/card/info/${card_id}`);
  }

  PublicGetHistoryOfClient(client_id, key) {
    return this.SendPost(`/public/push/client`, {client_id, key});
  }

  GetHistoryOfClient(client_id) {
    return this.SendGet(`/push/client/${client_id}`);
  }

  GetPostClientInfo(key, card_id) {
    return this.SendPost(`/client/info`, {key, card_id});
  }

  GetPublicClientInfo(key, card_id) {
    return this.SendPost(`/public/client/info`, {key, card_id});
  }

  GetClientInfo(id, card_id) {
    return this.SendGet(`/client/info/${card_id}/${id}`);
  }

  GetHistoryOfPushClient(id, card_id, limit?, offset?) {
    return this.SendGet(
      `/push/client/${id}?card_id=${card_id}&limit=${limit}&offset=${offset}`,
      LoadingStates.TAB_CONTENT
    );
  }

  GetPublicHistoryOfPushClient(key, card_id) {
    return this.SendPost(`/public/push/client`, {key, card_id});
  }

  GetPublicClientСard(key, card_id) {
    return this.SendPost(
      `/public/client/card`,
      {key, card_id},
      LoadingStates.CLIENT_PAGE
    );
  }

  GetClientСard(id) {
    return this.SendGet(`/client/card/${id}`, LoadingStates.TAB_CONTENT);
  }

  GetPublicClientPointsHistory(key, card_id) {
    return this.SendPost(`/public/client/points_history`, {key, card_id});
  }

  GetClientPointsHistory(id, card_id) {
    return this.SendGet(`/client/points_history/${card_id}/${id}`);
  }

  SendCustomGet(url: string) {
    return this.http.get(url);
  }

  GetDashboard() {
    return this.SendGet(
      `/partner/dashboard`,
      LoadingStates.DASHBOARD_DATA
    );
  }

  GetClientInfoForPartner(id) {
    return this.SendGet(
      `/partner/client/info/${id}`,
      LoadingStates.CLIENT_INFO
    );
  }

  SendPushToClient(id, text, title) {
    return this.SendPost(
      `/partner/client/send_push/${id}`,
      {text, title},
      LoadingStates.TAB_CONTENT
    );
  }

  getCardLevels(card_id) {
    return this.SendGet(`/card/levels/${card_id}`, LoadingStates.TAB_CONTENT);
  }

  SendHelp(data) {
    return this.SendPost(`/user/support_messages`, data);
  }

  getCurrentTariff() {
    return this.SendGet(`/tariffs/me`, LoadingStates.SETTINGS);
  }

  getInvoice() {
    return this.SendPost("/payments/invoice", {}, LoadingStates.INVOICE);
  }

  saveInvoice() {
    return this.http
      .get(`${this.ServerURL}/payments/invoices/last`, {
        headers: this.headers,
        responseType: "blob" as "json",
        observe: "response",
      })
      .pipe(tap(this.downloadService.saveResponse2File));
  }

  TarrifNotification() {
    return this.SendPost(`/tariffs/notification`, {});
  }


  checkPhone(phone: String) {
    return this.http.post(
      `${this.ServerURL}/phone_verifications/check_phone`,
      {phone},
      {headers: this.headers}
    );
  }

  requestPhoneCall(phone: String) {
    return this.http.post(
      `${this.ServerURL}/phone_verifications/request_secret/call`,
      {phone},
      {headers: this.headers}
    );
  }

  confirmPhone(phone: String, secret_code: String) {
    return this.http.post(
      `${this.ServerURL}/phone_verifications/confirm`,
      {phone, secret_code},
      {headers: this.headers}
    );
  }

  ResetPassword(data: any) {
    return this.SendPostAuth(`/partner/reset`, data, LoadingStates.RESET_PASSWORD);
  }

  ChangePassword(data: any) {
    return this.SendPost(`/partner/change-password`, data, LoadingStates.CHANGE_PASSWORD,this.ServerAuthURL);
  }

  toBlob(file: any): Observable<Blob> {
    return from(
      fetch(file)
        .then(res => res.blob())
        .then(blob => blob)
    );
  }

  SetBotToken(telegramId: any, type: 'CLIENT' | 'OPERATOR' | 'SUPPORT' = 'CLIENT', loading = this.loadingService.StatesNames.SET_BOT_TOKEN,) {
    return this.SendPost('/partner/bot', {telegramId, type}, loading, this.ServerBotUrl)
  }

  GetBotToken(loading = this.loadingService.StatesNames.GET_BOT_TOKEN,) {
    return this.SendGet('/partner/bot-info', loading, this.ServerBotUrl)
  }

  GetTgBotName(BOT_TOKEN) {
    return this.RequestHandler(() => {
      return this.http.get(`https://api.telegram.org/bot${BOT_TOKEN}/getMe`, {headers: this.headers});
    }, this.loadingService.StatesNames.SET_BOT_TOKEN);

  }
}
