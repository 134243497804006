import { LY_THEME, LY_THEME_NAME, LyHammerGestureConfig, LyTheme2, StyleRenderer } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import {CommonModule, registerLocaleData} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import {provideRouter, RouterModule, RouterOutlet} from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import {provideCharts, withDefaultRegisterables} from "ng2-charts";
import {ErrorPageComponent} from "./shared-module/error-page/error-page.component";
import {PreloaderComponent} from "./shared-module/ui-general/preloader/preloader.component";
import {APP_ROUTES} from "./app.routes";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {provideEnvironmentNgxMask} from "ngx-mask";

registerLocaleData(localeRu, "ru");

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    ColorPickerModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    HammerModule,
    PreloaderComponent,
    RouterOutlet,
    BrowserAnimationsModule
  ],
  providers: [
    provideRouter(APP_ROUTES),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: LyHammerGestureConfig,
    },
    StyleRenderer,
    LyTheme2,
    { provide: DEFAULT_CURRENCY_CODE, useValue: "RUB" },
    { provide: LOCALE_ID, useValue: "ru" },
    { provide: LY_THEME_NAME, useValue: "minima-light" },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    provideCharts(withDefaultRegisterables()),
    provideEnvironmentNgxMask()
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
