import { Injectable } from '@angular/core';

import { PartnerStatus } from '../models/Partner.model';
import { BaseState } from './abstract-base-state.service';

@Injectable({
  providedIn: "root",
})
export class TariffService extends BaseState<TariffStatus | null> {
  constructor() {
    super(null);
  }

  setStatus(status: TariffStatus | null) {
    this.set(status);
  }

  clear() {
    this.set(null);
  }
}

export type TariffStatus = Omit<
  PartnerStatus,
  "test_mode_expired" | "payment_required" | "blocked" | "soon_payment"
>;
