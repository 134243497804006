import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imageURL",
  standalone:true
})
export class ImageURLPipe implements PipeTransform {
  /**
   *
   * @param value - image url or base64
   * @param args  - image params args[0] is image height, args[0] is image width
   * @returns if value is base64 then returs is value;
   *  if value is a url then return is url with args;
   *  if value is null or undefined or empty string, return is value.
   */

  transform(value: string, ...args: any[]): unknown {
    const matcher = /data:image/;
    if (!value || matcher.test(value)) {
      return value;
    }
    //console.log("url",value);
    const url = new URL(value);
    args[0] && url.searchParams.append("w", args[0].toString());
    args[1] && url.searchParams.append("h", args[1].toString());
    return url.toString();
  }
}
