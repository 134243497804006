// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadings-logs {
  position: absolute;
  background: rgba(255, 255, 255, 0.89);
  padding: 15px;
  right: 30px;
  top: 10px;
  font-size: 13px;
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qCAAA;EACA,aAAA;EACA,WAAA;EACA,SAAA;EAGA,eAAA;EACA,aAAA;AADJ","sourcesContent":[".loadings-logs {\n    position: absolute;\n    background: rgba(255, 255, 255, 0.89);\n    padding: 15px;\n    right: 30px;\n    top: 10px;\n    // width: 500px;\n    // overflow: hidden;\n    font-size: 13px;\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
