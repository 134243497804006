export const APP_ROUTES:any =       [
  {path: "", pathMatch: "full", redirectTo: "entry"},
  {
    path: "entry",
    loadChildren: () => {
      return import("./entry-module/entry-module.module").then(
        (m) => m.EntryModule
      );
    },
  },
  {
    path: "auth",
    loadChildren: () => {
      return import("./auth-modules/auth.routes").then(
        (m) => m.AUTH_ROUTES
      );
    },
  },
  {
    path: "base",
    loadChildren: () => {
      return import("./base-modules/base.module").then(
        (m) => m.BaseModule
      );
    },
  },
  /*{path: "**", component: ErrorPageComponent},*/
];
